import styles from './404.module.css';
import { MaxWidth } from '../components/MaxWidth';
import { ButtonLink } from '../components/Button';
import React from 'react';
import { GetStaticProps } from 'next';
import { PublicAndTypeStaticProperties } from 'utils/interfaces';

export const getStaticProps: GetStaticProps<PublicAndTypeStaticProperties> = () => ({
  props: { isPublicPage: true },
});

const FourOhFour = () => (
  <div className={styles.inner}>
    <MaxWidth>
      <h1 className={styles.title}>404</h1>
      <h2 className={styles.subtitle}>Pagina niet gevonden</h2>
      <p className={styles.text}>De pagina die je probeert op te vragen bestaat niet (meer).</p>
      <div className={styles.buttonContainer}>
        <ButtonLink href="/" icon="arrowRight">
          Terug naar home
        </ButtonLink>
      </div>
    </MaxWidth>
  </div>
);

export default FourOhFour;
